<!--BPM业务流程管理系统-->
<template>
  <div class="bpm-container">
    <new-cloude-header/>
    <headerBanner :content="bannerContent" title="BPM业务流程管理系统" @btnClick="bannerClick"
                  imgStyle="width:481px;height:353px" :imgSrc="`${require('@/assets/img/solution/BPM业务流程管理系统.png')}`"
                  contentStyle="width:90%" :bodyStyle="bodyStyle"/>
    <!--流程管理面临的难题-->
    <div class="module-1">
      <div class="module-content">
        <p class="module-title">流程管理面临的难题</p>
        <div class="module-col">
          <el-row :gutter="20">
            <template v-for="list of dataList">
              <el-col :span="8">
                <div class="col-content">
                  <p class="default-title">{{ list.title }}</p>
                  <p class="introduce">{{ list.introduce }}</p>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <!--BPM系统核心亮点-->
    <div class="module-2">
      <div class="module-content">
        <p class="module-title">BPM系统核心亮点</p>
        <div class="module-tabs">
          <template v-for="list of datalist2">
            <div :class="activeName==list.value ? 'active tabs-list' : 'tabs-list'" @click="activeName=list.value">
              {{ list.name }}
            </div>
          </template>
        </div>
        <div class="tabs-content">
          <el-row :gutter="40">
            <template v-for="data of datalist2">
              <template v-if="data.value==activeName">
                <template v-for="data2 of data.list">
                  <el-col :span="12">
                    <div class="tabs-col">
                      <p class="tabs-title">{{ data2.name }}</p>
                      <ul>
                        <template v-for="ul of data2.children">
                          <li class="list-style-type">{{ ul }}</li>
                        </template>
                      </ul>
                    </div>
                  </el-col>
                </template>
              </template>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <!--自动化报表和决策支持-->
    <div class="module-3">
      <div class="module-content">
        <p class="module-title" style="color: #FFFFFF">自动化报表和决策支持</p>
        <div class="module-right-img"/>
        <div class="module-introduce">
          <div class="introduce-content">
            <div class="module-ul">
              <template v-for="list of datalist3">
                <ul>
                  <li class="list-style-type">
                    {{ list.title }}
                    <ul>
                      <li class="none-list-style" v-html="list.introduce"/>
                    </ul>
                  </li>
                </ul>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--帮企数智云BPM的产品优势-->
    <div class="module-4">
      <div class="module-content">
        <p class="module-title">帮企数智云BPM的产品优势</p>
        <div class="module-col">
          <el-row :gutter="15">
            <template v-for="list of datalist4">
              <el-col :span="6">
                <div class="col-content">
                  <p class="default-title">{{ list.title }}</p>
                  <p class="introduce">{{ list.introduce }}</p>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'BPM业务流程管理系统'"/>
    <new-cloude-footer/>
  </div>
</template>
<script>

import NewCloudeHeader from "@/views/home/newCloudeHeader";
import NewCloudeFooter from "@/views/home/newCloudeFooter";
import headerBanner from "@/components/assembly/headerBanner";
import applyTrial from "@/components/assembly/applyTrial";

export default {
  name: "bpm",
  components: {NewCloudeFooter, NewCloudeHeader, headerBanner,applyTrial},
  data() {
    return {
      bannerContent: 'BPM是整个协同办公平台的血脉，它是连接并打通其它各个应用模块之间协同的关键所在。通过BPM，既可以帮助用户基于企业业务模式和管理模式，自行定义所需要的各种流程应用，快速构建企业自身的流程管控体系，同时也为建设企业整体协同平台夯实基础。',
      bodyStyle: `background-image:url(${require('@/assets/img/solution/beijjing.png')});background-size: 400px;background-repeat: no-repeat;background-position: right 25px;`,
      dataList: [
        {title: '流程制度落地难', introduce: '组织制度多，不能通过流程固化E化快速落地、高效执行'},
        {title: '个性流程支撑弱', introduce: '流程建模功能有限、不灵活，组织个性化流程需求难满足'},
        {title: '流程集成成本高', introduce: '业务多、异构系统多，对接频繁，打通端到端流程成本高'},
        {title: '业务流程效率低', introduce: '系统多、入口没统一，登陆切换麻烦，影响业务流程效率'},
        {title: '流程审批不及时', introduce: '流程待办多、无分类，不支持移动审批，流程审批易滞后'},
        {title: '流程优化不精准', introduce: '流程数据统计分析费力，精确不到人与事，优化效果不好'},
      ],
      activeName: '1',
      datalist2: [
        {
          name: '办公协同流程',
          value: '1',
          list: [
            {
              name: '出差申请流程',
              children: [
                '在线申请，移动快批，不耽搁行程', '数据自动同步，出差期间考勤无忧', '直连商旅平台，住行一次全部安排好'
              ]
            },
            {
              name: '固资盘点流程',
              children: [
                '同步资产盘点计划，直接发起盘点', '移动扫码，免手工输入盘点单更便捷', '数据自动汇总，资产台账一目了然'
              ]
            },
            {
              name: '会议发起流程',
              children: [
                '在线预约会议，日程冲突自动提示', '会议资料在线共享，提高开会效率', '会议决议一键转督办，让执行有保障'
              ]
            },
            {
              name: '故障报修流程',
              children: [
                '自带客户、合同信息，简化报障单输入', '故障描述提示，问题清楚呈现好解决', '故障单发布，多点触达专家，快速处理'
              ]
            },
          ]
        },
        {
          name: '业务协同流程',
          value: '2',
          list: [
            {
              name: '合同审核流程',
              children: [
                '重要条款自带突显，智能审核降风险', '支持多人在线修改留痕，合同更透明', '随时随地移动审核，助推合同早签署'
              ]
            },
            {
              name: '费用报销流程',
              children: [
                '发票拍照、导入，手机轻松发起报销', '要点提示，让业务、财务主管审得准', '流程节点可视，一健催办，到账更快'
              ]
            },
            {
              name: '采购询价流程',
              children: [
                '关联采购计划，一键快速发起询价单', '询价单在线，让供应商报价更便捷', '供应商报价自动汇总，方便价格比对'
              ]
            },
            {
              name: '项目立项流程',
              children: [
                '创建项目组织，可邀请外部人员', '目标、任务在线分派，一目了然', '自动生成项目空间，沟通协作高效'
              ]
            },
          ]
        },
        {
          name: '行业特色流程',
          value: '3',
          list: [
            {
              name: '档案管理流程',
              children: [
                '覆盖人、财、工程、科技等多类档案', '连接多业务系统，一键在收归档更高效', '灵活配置借阅、处理等流程，管理更标准'
              ]
            },
            {
              name: '生产计划流程',
              children: [
                '集成SAP系统，可在OA内发起流程', '制程报废、领料审批、工艺路线维护申请', '一键发起，快速审批，让生产计划高效执行'
              ]
            },
            {
              name: '公文流程',
              children: [
                '符合国标，支持多组织复杂公文流转', '移动签批，领导出差也能高效发文', '适配信创“流版签”，更安全、省心'
              ]
            },
            {
              name: '场所巡检流程',
              children: [
                '巡检任务在线，打开手机一目了然', '巡检路线可视化，适配NFC功能打卡', '巡检直连工单流程，确保发现异常及时上报'
              ]
            },
          ]
        },
      ],
      datalist3: [
        {title: '自动化业务报表', introduce: '自定义需要的报表样式和数据来源；系统自动从电子单据中汇总关键业务数据，自动生成业务报表'},
        {title: '自动化过程报表', introduce: '系统自动生成全部单据的累计/平均用时、各环节的累计/平均用时、所有人员处理单据的数量和及时率'},
        {title: '自动化统计图表', introduce: '从业务报表和过程报表中，自动生成各种柱图、饼图、趋势图等，按照时间段筛选比对，支持经营决策'},
      ],
      datalist4:[
        {title: '架构行业领先', introduce: '国际标准化流程引擎架构，融合500强实践，适配多类型组织'},
        {title: '表单配置可视', introduce: '表单控件多元化、配置图形化，拖拉即可设计出组织需要表单'},
        {title: '流程设计灵活', introduce: '可视化建模效率高，自由设定并行、串行、自由流等流程路由'},
        {title: '全量系统集成', introduce: '无缝集成各类异构系统，让端到端BPM业务流程管理更高效'},
        {title: '流程功能强大', introduce: '支持统一模板管理、流程仿真、流程交接等，流程营运更简单'},
        {title: '流程分析智能', introduce: '通过流程、节点、人员等多维分析，快速诊断，精准优化流程'},
        {title: '流程审批智能', introduce: '待办汇总、批量审批、智能检查、要点提示…让审批智能高效'},
        {title: '移动流程高效', introduce: '手机上即可完成通过、驳回、转办等审批，全面提效业务协作'},
      ]
    }
  },
  methods:{
    bannerClick() {
      this.$refs.dialog.show = true;
    }
  }
}
</script>
<style scoped lang="scss">
.bpm-container {
  background: #FFFFFF;

  .module-1 {
    background: #FFFFFF;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-col {
        .col-content {
          background: #F7F8FF;
          padding: 30px;
          margin-bottom: 20px;

          .default-title {
            font-size: 18px;
            font-family: 'SourceHanSansCN';
            color: #333333;
            padding-left: 10px;
            border-left: 4px solid #1935DE;
            margin-block-end: 15px;
            margin-block-start: 0px;
            line-height: 18px;
          }

          .introduce {
            font-size: 16px;
            font-family: "SourceHanSansCN";
            color: #333333;
            margin-block-end: 0px;
            margin-block-start: 0px;
            width: 75%;
          }
        }
      }
    }
  }

  .module-2 {
    background: #F7F8FE;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-tabs {
        width: 1000px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 60px;
        border: 1px solid #DDDDDD;
        background: #F7F8FE;

        .tabs-list {
          color: #333333;
          font-family: 'SourceHanSansCN';
          font-size: 18px;
          padding-bottom: 15px;
          padding-top: 15px;
          width: 33.33333%;
          text-align: center;
          border-radius: 60px;
          cursor: pointer;
        }

        .active {
          background: #1935DE;
          color: #FFFFFF;
        }
      }

      .tabs-content {
        margin-top: 60px;

        .tabs-col {
          padding: 30px 20px 20px;
          background: #FFFFFF;
          margin-bottom: 30px;

          .tabs-title {
            font-size: 18px;
            color: #333333;
            font-family: 'SourceHanSansCN';
            margin-block-start: 0px;
            margin-block-end: 0px;
          }

          ul {
            margin-block-start: 20px;
            margin-block-end: 0px;
            margin-inline-start: 15px;
            margin-inline-end: 0px;
            padding-inline-start: 0px;
            font-size: 16px;
          }

          .list-style-type {
            list-style-type: "◆";
            padding-left: 10px;
            color: #333333;
            font-family: "SourceHanSansCN";
            padding-bottom: 10px;
          }

          ::marker {
            color: #1935DE;
            unicode-bidi: isolate;
            font-variant-numeric: stacked-fractions;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
            font-size: xx-small;
          }
        }
      }
    }
  }
  .module-title {
    font-family: 'SourceHanSansCN';
    color: #333333;
    font-size: 30px;
    margin-block-start: 0px;
    margin-block-end: 40px;
    text-align: center;
  }
  .module-3 {
    background: url("~@/assets/img/home/7_0HsoQd_9K2.jpg") no-repeat;
    background-size: 100% 100%;
    height: 640px;
    width: 100%;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-right-img {
        height: 400px;
        width: 580px;
        background: url("~@/assets/img/solution/自动化报表和决策支持.png") no-repeat;
        background-size: 100% 100%;
        float: right;
        margin-top: 40px;
      }

      ul {
        margin-block-start: 0px;
        margin-block-end: 20px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 13px;
      }

      .none-list-style {
        list-style-type: none;
        margin-top: 5px;
      }

      .list-style-type {
        list-style-type: "◆";
        padding-left: 10px;
        color: #333333;
        font-family: 'SourceHanSansCN';

        ul {
          margin-block-start: 0px;
          margin-block-end: 0px;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 0px;
          font-size: 14px;
        }
      }

      ::marker {
        color: #1935DE;
        unicode-bidi: isolate;
        font-variant-numeric: stacked-fractions;
        text-transform: none;
        text-indent: 0px !important;
        text-align: start !important;
        text-align-last: start !important;
        font-size: xx-small;
      }

      .module-ul {
        overflow-y: auto;
        padding-right: 20px;
      }

      .module-introduce {
        height: 255px;
        width: 580px;
        background: rgba(255, 255, 255, 0.9);
        z-index: 999;
        position: absolute;
        margin-left: 115px;
        margin-top: 70px;
        padding: 30px 0px 30px 20px;
      }
    }
  }
  .module-4 {
    background: #FFFFFF;
    height: 467px;
    width: 100%;
    padding-bottom: 60px;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .module-col {
        .col-content {
          background: #F7F8FF;
          padding: 30px 20px;
          margin-bottom: 15px;

          .default-title {
            font-size: 18px;
            font-family: 'SourceHanSansCN';
            color: #333333;
            margin-block-end: 15px;
            margin-block-start: 0px;
          }
          .introduce {
            font-size: 16px;
            font-family: "SourceHanSansCN";
            color: #333333;
            margin-block-end: 0px;
            margin-block-start: 0px;
          }
        }
      }
    }
  }
}
</style>
